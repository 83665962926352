"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSearchBoxValuesToStorage = exports.getSearchBoxValuesFromStorage = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _storage = require("../constants/storage");
var _getStorageWithExpiry = _interopRequireDefault(require("./getStorageWithExpiry"));
var _isDate = _interopRequireDefault(require("./isDate"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const correctDate = value => {
  const date = new Date(value);
  if ((0, _isDate.default)(date) && (0, _isAfter.default)(date, new Date())) {
    return (0, _parseToDateString.default)(date);
  }
  return undefined;
};
const setSearchBoxValuesToStorage = searchBarValues => {
  const storageUtils = (0, _getStorageWithExpiry.default)('local');
  storageUtils?.setItem(_storage.SEARCHBAR_KEY, JSON.stringify(searchBarValues));
  let visitorParty = 'other';
  if ((searchBarValues.booking.amountChildren ?? 0) > 0 || (searchBarValues.booking.amountBabies ?? 0) > 0 || (searchBarValues.booking.amountYouths ?? 0) > 0) {
    visitorParty = 'family';
  } else if (searchBarValues.booking.amountAdults === 2) {
    visitorParty = 'couple';
  } else if ((searchBarValues.booking.amountAdults ?? 0) > 6) {
    visitorParty = 'group';
  }
  storageUtils?.setItem(_storage.VISITOR_PARTY, visitorParty, 365);
};
exports.setSearchBoxValuesToStorage = setSearchBoxValuesToStorage;
const getSearchBoxValuesFromStorage = () => {
  const storageUtils = (0, _getStorageWithExpiry.default)('local');
  if (!storageUtils) {
    return undefined;
  }
  const {
    getItem
  } = storageUtils;
  const searchString = getItem(_storage.SEARCHBAR_KEY);
  if (!searchString) {
    return undefined;
  }
  const searchObj = JSON.parse(searchString);
  let {
    arrivalDate,
    departureDate
  } = searchObj.booking ?? {};
  arrivalDate = correctDate(arrivalDate);
  departureDate = correctDate(departureDate);
  if (!arrivalDate || !departureDate) {
    arrivalDate = '';
    departureDate = '';
  }
  return _objectSpread({
    booking: _objectSpread(_objectSpread({}, searchObj.booking), {}, {
      arrivalDate,
      departureDate
    })
  }, searchObj.accommodationType && {
    accommodationType: searchObj.accommodationType
  });
};
exports.getSearchBoxValuesFromStorage = getSearchBoxValuesFromStorage;