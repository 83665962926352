import styled from 'styled-components';
import {
    getInitialSearchBoxValues,
    getSearchValidationSchema,
} from '@oberoninternal/travelbase-website/dist/components/SearchBox';
import React from 'react';
import { useRouter } from 'next/router';
import Formed from '@oberoninternal/travelbase-website/dist/components/Formed';
import { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { withDefaultBookingValues } from '@oberoninternal/travelbase-website/dist/utils/trip';
import { Booking } from '@oberoninternal/travelbase-website/dist/entities/Booking';
import { useTenantContext } from '@oberoninternal/travelbase-website/dist/context/TenantContext';
import {
    getSearchBoxValuesFromStorage,
    setSearchBoxValuesToStorage,
} from '@oberoninternal/travelbase-website/dist/utils/searchBoxValuesFromStorage';
import SearchBar from '@oberoninternal/travelbase-website/dist/components/SearchBar';

interface Values {
    accommodationType: OptionType['value'];
    booking: Booking;
}

const SearchAndBook = () => {
    const { push } = useRouter();
    const validationSchema = getSearchValidationSchema();
    const { brandConfig } = useTenantContext();

    return (
        <Wrapper>
            <Formed<Values>
                handleSubmit={async values => {
                    const { accommodationType, booking } = values;
                    setSearchBoxValuesToStorage(values);
                    await push({
                        pathname: `/search`,
                        query: {
                            ...withDefaultBookingValues(booking),
                            ...(accommodationType && { accommodationType }),
                        },
                    });
                }}
                initialValues={getInitialSearchBoxValues(brandConfig, getSearchBoxValuesFromStorage())}
                validationSchema={validationSchema}
                skipPrompt
            >
                {() => <SearchBar hideSearchIcon variant="homeBar" />}
            </Formed>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

export default SearchAndBook;
