import Image from 'next/legacy/image';
import React, { FC } from 'react';
import { Box, Flex } from '@rebass/grid';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage, useIntl } from 'react-intl';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import styled from 'styled-components';

const TerschellingHoliday: FC<React.PropsWithChildren<unknown>> = () => {
    const { formatMessage } = useIntl();
    return (
        <Container
            my={[6, 8]}
            pt={[6, 8]}
            flexDirection={['column', null, 'row']}
            justifyContent="space-around"
            alignItems="center"
        >
            <StyledBoxImage width={[1, 1, 1, 1 / 2]} mb={[0, 0, 6, 0]}>
                <Image
                    loading="lazy"
                    src="/static/img/vlag-strandhotel.png"
                    alt={formatMessage({ defaultMessage: 'Strandhotel' })}
                    layout="responsive"
                    width={688}
                    height={459}
                />
            </StyledBoxImage>
            <Box mt={[5, null, 0]} width={[1 / 1, 1 / 1, 1 / 1, 1 / 2]} ml={[0, 0, 0, 7]}>
                <Title variant="small">
                    <FormattedMessage defaultMessage="Vakantie op Terschelling" />
                </Title>
                <StyledBox mt={[2, null, 3]}>
                    <Body variant="regular">
                        <FormattedMessage defaultMessage="Vakantie vier je op Terschelling. Op Terschelling ervaar je het ware eilandgevoel. Waar je ook bent, je proeft, ruikt, ziet of voelt de zee. Een uitgestrekt eiland met gezellige dorpen en een fantastische natuur. Echt even helemaal weg. Pak de fiets buitendijks langs de Waddenzee of door de duinen. Eindeloze stranden nodigen uit tot heerlijke strandwandelingen. Of je nu alleen bent, met familie, vrienden of kinderen, er is veel te zien en te doen." />
                    </Body>
                </StyledBox>
                <StyledBox mt={[2, null, 5]}>
                    <Title variant="small">
                        <FormattedMessage defaultMessage="Verblijf boeken op Terschelling" />
                    </Title>
                </StyledBox>

                <StyledBox mt={[2, null, 3]}>
                    <Body variant="regular">
                        <FormattedMessage defaultMessage="Boek een vakantie op dit prachtige eiland. Een uitgebreid aanbod van vakantiehuisjes, hotels tot campings. Boek je verblijf eenvoudig online. Er zijn vele soorten beschikbaar. Van luxe bungalow of appartement tot een heerlijk hotel. Je hebt de keuze uit honderden verschillende accommodaties. Kun je gelijk je overtocht boeken naar Terschelling. Weten wat er te doen is? Bekijk de evenementen of boek een excursie. De voorpret kan alvast beginnen. Tot snel op Terschelling." />
                    </Body>
                </StyledBox>
            </Box>
        </Container>
    );
};

const Container = styled(Flex)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 56px;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-direction: row;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        align-items: center;
    }
`;
const StyledBox = styled(Box)`
    p {
        margin-bottom: 0.8rem;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
            margin-bottom: 1.6rem;
        }
    }
`;

const StyledBoxImage = styled(Box)`
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.primary['20']};
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
`;

export default TerschellingHoliday;
