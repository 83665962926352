const BASE_URL_NL = process.env.NEXT_PUBLIC_DOMAIN_NL ?? 'https://www.vvvterschelling.nl';
const BASE_URL_EN = process.env.NEXT_PUBLIC_DOMAIN_EN ?? 'https://www.vvvterschelling.com';
const BASE_URL_DE = process.env.NEXT_PUBLIC_DOMAIN_DE ?? 'https://www.vvvterschelling.de';
const isProd = process.env.NODE_ENV === 'production';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCurrentBaseUrl = (locale: any): string => {
    switch (locale) {
        case 'nl':
            return isProd ? `https://${BASE_URL_NL}` : `http://${BASE_URL_NL}`;
        case 'en':
            return isProd ? `https://${BASE_URL_EN}` : `http://${BASE_URL_EN}`;
        case 'de':
            return isProd ? `https://${BASE_URL_DE}` : `http://${BASE_URL_DE}`;
        default:
            return isProd ? `https://${BASE_URL_NL}` : `http://${BASE_URL_NL}`;
    }
};

export default getCurrentBaseUrl;
